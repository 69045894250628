import React, { Component } from "react";

class Education extends Component {
    static reset() {
        document.getElementById("EducationLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("HomeLink").setAttribute("style", "background-position: ''");
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: ''");
        document.getElementById("SkillsLink").setAttribute("style", "background-position: ''");
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: ''");
        document.getElementById("LinksLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Education.reset;
        if (document.readyState === "complete"){
            Education.reset();
        }
        return(
            <div className="info">
                    <h3><b>University of California, Davis</b></h3>
                    <p>Bachelor of Science, Degree Anticipated 2019</p>
                    <ul id="Home">
                        <li><b>Major:</b> Computer Science</li>
                        <li><b>GPA:</b> 3.54</li>
                        <li><b>Academic Honors:</b> Dean's List (Spring 2016, Fall 2017)</li>
                    </ul>
            </div>
        )
    }
}

export default Education;