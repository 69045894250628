import React, {Component} from 'react';
import './App.css';
import {
    Route,
    NavLink,
    HashRouter,
    Redirect
} from "react-router-dom";
import Experience from "./Experience"
import Projects from "./Projects";
import Skills from "./Skills";
import Home from "./Home";
import Education from "./Education";
import Links from "./Links";

class App extends Component {

    render() {
        return (
            <HashRouter>
                <div>
                    <Route exact path="/" render={() => (
                        <Redirect className="Hover" to="/home"/>
                    )}/>
                    <div className="App">
                            <ul id="Home">
                                <li><NavLink id="HomeLink" className="Hover" to= "/home">
                                    Home
                                </NavLink></li>
                                <li><NavLink id="EducationLink" className="Hover" to="/education">
                                    Education
                                </NavLink></li>
                                <li><NavLink id="ExperienceLink" className="Hover" to="/experience">
                                    Experience
                                </NavLink></li>
                                <li><NavLink id="ProjectsLink" className="Hover" to= "/projects">
                                    Projects
                                </NavLink></li>
                                <li><NavLink id="SkillsLink" className="Hover" to= "/skills">
                                    Skills
                                </NavLink></li>
                                <li><NavLink id="LinksLink" className="Hover" to="/links">
                                    Links
                                </NavLink></li>
                                <li><a id="ContactLink" className="Hover" href= "mailto:mostrowski@ucdavis.edu">
                                    Contact
                                </a></li>
                            </ul>
                    </div>
                    <div className="Box">
                        <div className="info">
                            <Route path="/home" component={Home}/>
                            <Route path="/education" component={Education}/>
                            <Route path="/experience" component={Experience}/>
                            <Route path="/skills" component={Skills}/>
                            <Route path="/projects" component={Projects}/>
                            <Route path="/links" component={Links}/>
                        </div>
                    </div>
                </div>
            </HashRouter>
        );
    }
}

export default App;
