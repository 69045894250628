import React, { Component } from "react";

class Links extends Component {
    static reset() {
        document.getElementById("LinksLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("EducationLink").setAttribute("style", "background-position: ''");
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: ''");
        document.getElementById("SkillsLink").setAttribute("style", "background-position: ''");
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: ''");
        document.getElementById("HomeLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Links.reset;
        if (document.readyState === "complete"){
            Links.reset();
        }
        return(
            <div className="info">
                <ul id="Home">
                    <li><a className="Hover" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/matthew-ostrowski/">LinkedIn</a></li>
                    <li><a className="Hover" target="_blank" rel="noopener noreferrer" href="https://github.com/mostrowski123">GitHub</a></li>
                </ul>
            </div>
        )
    }
}

export default Links;