import React, { Component } from "react";

class Projects extends Component {
    static reset() {
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("EducationLink").setAttribute("style", "background-position: ''");
        document.getElementById("HomeLink").setAttribute("style", "background-position: ''");
        document.getElementById("SkillsLink").setAttribute("style", "background-position: ''");
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: ''");
        document.getElementById("LinksLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Projects.reset;
        if (document.readyState === "complete"){
            Projects.reset();
        }
        return(
            <div className="info">
                <h3>Automation programs</h3>
                <p>The bank relies on multiple vendors for its core banking software. Some of this software has
                    ways of generating reports but there is no direct connection available to a live data feed.</p>
                <p>In order to solve this problem I developed multiple automation programs with the help of the
                    Selenium WebDriver. I was able to generate up to the minute reports for the status of ATM's and
                    Online Banking accounts. In addition I was able to generate daily reports of support tickets to
                    our core vendor, send them to interested parties, and the save reports in a SQL database
                    for tracking.
                </p>
                <p>Using this method I was able save the bank thousands of dollars in automation software. All of the
                    automation program are able to run headless on a single computer and can restart themselves if they
                    encounter errors. They can even mitigate some errors such as having to reset the password in a set
                    time frame.
                </p>
                <h3>Form filler</h3>
                <p> In the banking
                    industry access request forms are necessary to ensure employees have access to only what they need
                    access to. However filling out these forms is long and tedious and if a new product is introduced to
                    the bank's workflow every employee that will use that product now needs a form filled out.</p>
                <p>Form filler is a program written in the C# that fills out word and pdf documents based on a
                    list of names or email addresses and then sends an email to that employee's manager.</p>
                <p>The program interacts with the bank's employee SQL database to match email addresses and names to the
                    correct employee and then finds that employee's manager to email. Emails to the same manager are
                    grouped and the documents are attached and sent under the user's name. This is all done through a
                    GUI interface that allows the user to save multiple email templates and export generated
                    documents.</p>
                <h3>Intel x86 emulator</h3>
                <p>In C++ with the help of a partner, we were able to emulate an Intel 32-bit processor. Our program
                    would read a generated assembly file and perform actions on our virtual registers. We kept track of the
                    stack pointer, pc, and even had an area for virtual memory where data would be stored
                    and retrieved by the registers.
                </p>
            </div>
        )
    }
}

export default Projects;