import React, { Component } from "react";

class Skills extends Component {
    static reset() {
        document.getElementById("SkillsLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("EducationLink").setAttribute("style", "background-position: ''");
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: ''");
        document.getElementById("HomeLink").setAttribute("style", "background-position: ''");
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: ''");
        document.getElementById("LinksLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Skills.reset;
        if (document.readyState === "complete"){
            Skills.reset();
        }
        return(
            <div className="info">
                <ul id="NoMargin">
                    <li>Experienced with Git and Linux environments </li>
                    <li>Familiar with agile and waterfall approaches to software development</li>
                    <li>Able to transform time consuming tasks into automated programs saving time and money</li>
                    <li>Dedicated worker willing to take on any challenge to discover a solution to any problem</li>
                    <li>Avid learner willing to learn any necessary language to accomplish a given task</li>
                </ul>
                <h4>Programming Languages</h4>
                <ul><li>C#, C, C++, Swift, Python, Java</li></ul>
            </div>
        )
    }
}

export default Skills;