import React, { Component } from "react";

class Home extends Component {
    static reset() {
        document.getElementById("HomeLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("EducationLink").setAttribute("style", "background-position: ''");
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: ''");
        document.getElementById("SkillsLink").setAttribute("style", "background-position: ''");
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: ''");
        document.getElementById("LinksLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Home.reset;
        if (document.readyState === "complete"){
            Home.reset();
        }
        return(
            <div className="info">
                Hello, my name is Matthew Ostrowski and I am a current senior at the University of California, Davis.
                Currently, I work at Cape Cod 5 Cents Savings Bank creating automated C# applications.
                I am passionate about coding and love being able to help people increase their productivity at the bank.
                One of my proudest accomplishments at the bank is starting and maintaining
                the bank's very own git server to help manage the bank's growing code base.
            </div>
        )
    }
}

export default Home;