import React, { Component } from "react";

class Experience extends Component {
    static reset() {
        document.getElementById("ExperienceLink").setAttribute("style", "background-position: -100%;");

        document.getElementById("EducationLink").setAttribute("style", "background-position: ''");
        document.getElementById("ProjectsLink").setAttribute("style", "background-position: ''");
        document.getElementById("SkillsLink").setAttribute("style", "background-position: ''");
        document.getElementById("HomeLink").setAttribute("style", "background-position: ''");
        document.getElementById("LinksLink").setAttribute("style", "background-position: ''");
    }
    render() {
        window.onload = Experience.reset;
        if (document.readyState === "complete"){
            Experience.reset();
        }
        return(
            <div className="info">
                <h3>The Cape Cod 5 Cents Savings Bank<span className="notbold"> – Orleans, MA</span> </h3>
                <h4>Software Developer I, June 2017 to Present</h4>
                <ul>
                    <li>Designed multiple C# programs for automating time-consuming tasks such as
                        updating the status of all bank ATMs every minute. This has
                        saved the bank thousands of dollars in automation software costs</li>
                    <li>Organized and created the bank's own self hosted Git server to host intranet and code base</li>
                    <li>Managed the bank's intranet and SQL databases and fulfilled 90% of change requests for the
                        intranet</li>
                </ul>
                <h3>Fair Acres <span className="notbold"> – Centerville, MA</span></h3>
                <h4>Camp Counselor, Summer 2016</h4>
                <ul>
                    <li>
                        Developed a program in Python to create formatted schedules each week for each group of
                        campers
                    </li>
                    <li>Guided a group of 35 campers with 2 other counselors to help make summer camp as enjoyable as
                        possible
                    </li>
                </ul>
            </div>
        )
    }
}

export default Experience;